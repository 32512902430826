@use '/src/style/variables.scss' as var;

.root {
  display: inline-block;
  padding: 0 !important;
  margin: 0 !important;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 4px 8px;
  margin-left: 0px !important;
  border-radius: 100px;
  background-color: var.$white;
}

.container.voyage, 
.container.invertedvoyage {
  padding: 4px 16px;
}

.container.promotion,
.container.feature {
  border-radius: 0;
}

.container.promotion {
  background-color: var.$frozenBlue; 
}

.container.feature {
  background-color: var.$expeditionJungleGreen1; 
}

.container.invertedvoyage.isCoastal {
  background-color: var.$navyBlue2; 
}

.container.voyage {
  background-color: var.$black; 
}

.container.voyage.isCoastal {
  background-color: var.$navyBlue1; 
}

.tag {
  line-height: 1;
  padding-bottom: 1px;
  font-size: 12px;
  font-weight: 600;
  color: var.$black;
}

.tag.voyage,
.tag.invertedvoyage.isCoastal {
  color: var.$white;
}

.tag.voyage, 
.tag.invertedvoyage {
  font-size: 16px;
  font-weight: 700;
}

.tag.promotion,
.tag.feature {
  font-weight: 400;
}

.uppercase {
  text-transform: capitalize;
}