.animatedArrow {
  display: flex;
  width: 100%;
}

.animatedArrow:hover div {
  transform: translate(12px, 0);
}

.linkArrow {
  transition: all 0.3s ease-in-out;
  margin-left: 8px;
}