@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

// Fluid
.fluid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  flex: 1;
  padding: 0 16px;
  max-width: var.$wideDesktopWidth;

  @include mq.tablet {
    padding: 0 24px;
  }
}

.hasNoSpace {
  padding: 0;
}

.maxWidth {
  max-width: var.$desktopContainerWidth;
}


// LoadingWrapper
.loader {
  height: 70vh;
  width: 100%;
  background-color: var.$white;
}

// ContentfulRenderer
.videoContainer {
  position: relative;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.list {
  margin-block: 4px;
}

.listItem {
  & > * {
    margin-top: 0;
  }
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic !important;
}

.underline {
  text-decoration: underline !important;
}

.paragraph {
  min-height: 30px;
  
}

.contentGroup {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;

  table {
    display: table;
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 8px;
    border-color: gray;
    width: 100%; 
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  td {
    display: table-cell;
    vertical-align: inherit;
    word-break: normal;
    overflow-wrap: normal; 
  }

 
  td:nth-child(6) {
    word-break: break-word;
    overflow-wrap: break-word; 
    min-width: 150px;
  }

  @include mq.tablet {
    & p {
      word-break: normal;
      overflow-wrap: normal;
    }
    
    td:nth-child(6) p {
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}