@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

// Calendar
.calendarContainer {
  display: inline-flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;

  .content {
    z-index: 100;
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: 100%;
    background: var.$white;
    border: 1px solid var.$warmGray2;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, 100%);
    overflow-x: hidden;
    
  
  }

  .content div div {
    justify-content: center;
    align-items: center;
  }

  @include mq.tablet {
    .content {
      padding-block: 8px;
      width: 100%;
      max-width: 100%;
    }
  }

  @include mq.desktop {
    .content {
      flex-direction: column;
      gap: 16px;
      padding-block: 16px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }
  @include mq.desktop {
    .buttonContainer {
      justify-content: flex-end;
    }
  }

  .doneButton {
    align-items: center;
    margin-bottom: 16px;
    width: 50%;
  }

  @include mq.desktop {
    .doneButton {
      align-self: flex-end;
      margin-right: 16px;
    }
  }
  .specificDatesDropdown {
    width: 100%;
  }

  .specificDatesDropdown > div select {
    border: 1.4px solid var.$warmGray2;
  }
}

// CalendarFlexible
.calendarFlexibleContainer {
  margin-top: 16px
}

// CalendarFlexibleMonths
.calendarFlexibleMonthsContainer {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(4, 1fr);
  justify-content: center;
  gap: 8px;
  padding: 16px;

  @media (max-width: 600px) {
    .calendarFlexibleMonthsContainer {
      gap: 4px;
      padding: 8px;
    }
  }

  .checkbox {
    display: none;
  }

  .button {
    display: block;
    text-align: center;
    background-color: var.$white;
    border-radius: 8px;
    border: 1px solid var.$warmGray3;
    color: var.$textBlack;
    padding-inline: 16px;
    padding-block: 8px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  .button:hover {
    border: 1px solid var.$black;
    color: var.$textBlack;
  }

  .button:active {
    background-color: var.$black;
    color: var.$white;
  }

  .checked {
    background-color: var.$black;
    color: var.$white;
    border: 1px solid var.$black;
  }

  .checked:hover {
    background-color: var.$black;
    color: var.$white;
    border: 1px solid var.$black;
  }

  .disabled {
    color: var.$warmGray4;
    border: 1px solid transparent;
    cursor: default;
  }

  .disabled:hover,
  .disabled:active {
    border: 1px solid transparent;
    color: var.$warmGray4;
    background-color: var.$white;
  }

  .doneButton {
    grid-column: 3 / 4;
  }
}

// CalendarInput
.input {
  z-index: 10;
  padding: 15px 20px;
  box-sizing: border-box;
  border: 1.4px solid var.$black;
  background-color: var.$white;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
  max-width: var.$desktopContainerWidth;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .label,
  .label > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
  }

  .labelFlexible,
  .labelFlexible > span {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .labelFlexible > span:not(:last-child):after {
    content: "', '";
    white-space: pre;
  }

  .icon {
    margin-left: auto;
    min-width: 24px;
  }
}