@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/truncate.scss' as truncate;

.root {
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 10;
}

.label {
  font-weight: 500!important;
}

.promoResultCntr {
  display: flex;
  width: 100%;
}

.promoResultLeft, 
.promoResultRight {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.promoResultRight {
  align-items: flex-end;
}

.promoCodeText {
  color: var.$warmGray5;
}

.accessibilityContainer, 
.addPromotionContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 48px;
  z-index: 3;
}

.accessibilityContainer {
  margin-bottom: -10px;
}

.inputControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 15px 20px;
  background-color: var.$white;
  border: 1.4px solid var.$black;
  box-sizing: border-box;
}

.customIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 16px;
}

.customDropDownIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var.$black;
}

.valueContainer {
  display: block;
  flex: 5;
  font-size: 18px !important;
  line-height: 27px !important;
  box-sizing: content-box;
  text-align: left;
  align-items: center;
}

.cabinsCenter {
  position: absolute;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 8px 8px;
  background-color: var.$white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  max-height: 60vh;
  overflow-y: auto;
  z-index: 999;
}

.accessibilityIcon {
  padding-right: 6px;
}

.longVoyageNotification {
  padding-bottom: 16px;
}

.cabinRadioGroup > div {
  padding: 0 0 16px 0;
  margin-top: -10px;
}

.truncateWrapper {
  display: grid;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.coastalTooltip {
  position: absolute;
  width: 100%;
  padding: 16px;
  background-color: var.$black;
  color: var.$white;
  right: 0;
  z-index: 999;
  box-sizing: border-box;
  top: -60px;
}

.coastalTooltip.isCoastal {
  background-color: var.$navyBlue1;
  top: -20px;
}

.truncate {
  @include truncate.truncate;
}