@use '/src/style/variables.scss' as var;

.button {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 32px;
  background-color: transparent;
  border: none;
  color: var.$white;
  font-size: 14px !important;
  cursor: pointer;
}

.button.hasIcon {
  padding: 12px 10px;
}

.icon {
  transform: rotate(270deg);
}
