@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.tableRow {
  height: 100%;
}

.tableCell {
  width: 180px;
  background-color: var.$white;
}

.tableCell:first-child {
  width: 30%;
  border-radius: 10px 0 0 10px;
}

.tableCell:last-child > * {
  border-radius: 0 10px 10px 0;
}

.tableCellBodySecondary {
  height: 100%;
  display: flex;
}

.tableCellButton {
  flex: 1;
  border: none;
  font: inherit;
  outline: inherit;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  box-sizing: border-box;
  min-width: 8rem;
  position: relative;
}

.tableCellButton:hover,
.tableCellBodySecondary:hover {
  background-color: var.$volcanoBlack4;
}

.tableCellButton:disabled {
  background-color: var.$grey1;
  color: var.$grey3;
  cursor: not-allowed;
  border-radius: inherit;
  height: 100%;
}

.accessibleLabel {
  justify-content: flex-start;
  position: relative;
}

.disabledButton {
  color: var.$warmGray5;
  display: flex;
  justify-content: center;
  align-items: space-between;
  background-color: var.$volcanoBlack4;
  gap: 16px;
}

.disabledButtonLabel {
  max-width: 6rem;
}
