@use '/src/style/variables.scss' as var;

.container {
  max-width: 560px;
  display: flex;
  padding: 18px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.inputContainer {
  width: 100%;
}

.btnContainer {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.button {
  width: 100%;
  box-sizing: border-box;
}

.submitButton {
  margin-left: 30px;
}

.formContainer {
  width: 100%;
}

@media (max-width: 600px) {
  .inputContainer {
      margin-top: 30px;
  }

  .btnContainer {
    flex-direction: column;
  }

  .button {
    margin-top: 30px;
  }

  .submitButton {
    margin-left: 0;
    margin-top: 18px;
  }
}