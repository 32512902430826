@use '/src/style/variables.scss' as var;

.title {
  font-weight: 500!important;
  margin-bottom: 16px;
}

.title + p {
  margin-bottom: 32px;
  line-height: 1.71;
}

.formContainer {
  margin-bottom: 32px;
}

.inputRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  column-gap: 16px;
}

.inputContainer {
  width: 100%;
}

.buttonContainer {
  width: 100%;
}

.buttonContainer button {
  width: 100%;
  box-sizing: border-box;
}

.buttonContainer button:focus {
  outline: 2px solid var.$black
}

.countrySelect {
  display: block!important;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
