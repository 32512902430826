@use '/src/style/variables.scss' as var;

.root {
  display: flex;
  flex-direction: row;
  height: 450px;
  overflow-y: auto;
  padding-top: 40px;
}

.includedRoot,
.notIncludedRoot {
  flex: 1;
  font-family: 'Atlas Grotesk Regular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var.$warmGray6;
}

.notIncludedRoot {
  margin-left: 25px;
}

.title {
  font-family: 'Atlas Grotesk Medium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var.$black;
  margin-left: 22px;
}

@media (max-width: 600px) {
  .root {
    flex-direction: column;
    height: 100%;
  }

  .title {
    font-size: 14px;
  }

  .notIncludedRoot {
    margin-left: 0;
  }
}
