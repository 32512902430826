@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  width: 100%;
  border-spacing: 0px 2px;
}

.table {
  width: 100%;
  border-spacing: 0px 2px;
}

.tableHeader {
  padding: 0;
}

.tableHeader > * {
  background-color: var.$white;
}

.cabinCategory {
  background-color: var.$white;
  height: -webkit-fill-available;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tableCell {
  height: 0;
  padding: 0;
}

.tableCellTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cabinTypeLink {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.p2pSearchResultContainer {
  display: flex;
  flex-direction: column;
  margin: 6px;
  width: unset;
}

