@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: -1px;
  width: 100%;
  height: 50px;
  margin: 0px;
  padding: 0px;
  z-index: 10;
}

.navigation.hasBackground {
  background-color: var.$white;
}

.navigation.hasScroll {
  border: solid 1px var.$warmGray2;
  background-color: var.$white;
}

.menuButton {
  display: flex;
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background-color: var.$white;
  border: none;
  padding: 1rem;
  cursor: pointer;
}

.menuButton:hover {
  background-color: var.$warmGray2;
}

.navItem {
  font-family: 'Atlas Grotesk Medium', sans-serif;
  width: 100%;
  display: block;
  border: unset;
  cursor: pointer;
  text-decoration: none;
  color: var.$warmGray4;
  background-color: var.$white;
  padding: 15px;
}

.navItem > span {
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.navItem:hover {
  color: var.$warmGray3;
}

.navItemActive {
  font-family: 'Atlas Grotesk Medium', sans-serif;
  color: var.$black;
  padding: 15px;
}

.list {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: var.$white;
  white-space: nowrap;
}


.list.hasNoScroll {
  border: solid 1px var.$warmGray1;
}

.list li {
  width: 1fr;
}

@include mq.desktop {
  .navItem{
    font-family: 'Atlas Grotesk Medium', sans-serif;
    text-decoration: none;
    color: var.$warmGray4;
    cursor: pointer;
    border: unset;
    background-color: transparent;
    padding: 15px;
   }

   .navItemActive {
    color: var.$black;
    padding: 15px;
    border-bottom: 4px solid var.$black;
   }

  .list {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(248, 248, 248);
    display: flex;
    max-width: 1136px;
    align-items: center;
    justify-content: space-evenly;
  }
}

