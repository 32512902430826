@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/truncate.scss' as truncate;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  position: relative;
}

.label {
  font-weight: 500!important;
}

.inputControl {
  align-items: center;
  background-color: var.$white;
  border: 1.4px solid var.$black;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
}

.customDropDownIcon {
  align-items: center;
  color: var.$black;
  display: flex;
  justify-content: flex-end;
}

p.valueContainer {
  flex: 5;
  text-align: left;
  font-size: 18px!important;
  line-height: 27px;
}

.openContainer {
  box-shadow: 1px 4px 10px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  background-color: var.$white;
  border: 1.4px solid var.$black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 15px;
  z-index: 3;
  box-sizing: border-box;
  top: 57px;
  left: 0;
}

.carIcon {
  padding-right: 13px;
  box-sizing: content-box !important;
}


.truncate {
  @include truncate.truncate;
}

@include mq.mobile {
  .inputControl {
    border-right: 1.4px solid transparent;
  }
}