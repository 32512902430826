@use '/src/style/variables.scss' as var;

.useModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  backdrop-filter: blur(0.3px);
  background-color: rgba(53, 53, 53, 0.5);

  .card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    background-color: var.$white;
    padding: 1.5rem;
    min-width: 200px;
    max-width: 512px;
    min-height: 200px;
    margin: auto;
    border-radius: 8px;
  }
}