@use '/src/style/variables.scss' as var;

// MarketingDropdown
.dropdown {
  width: 100%;
  position: relative;
}

.buttonLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.dropdownItems {
  z-index: 3;
  position: absolute;
  width: 100%;
  display: grid;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16);
  background-color: var.$white;
}

.dropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var.$white;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid var.$warmGray3;
}

.dropdownItem:hover {
  background-color: var.$warmGray3;
  color: var.$white;
}

.dropdownItemLabel {
  margin: 16px 0 16px 16px;
  font-weight: 500!important;
}

.dropdownItemIcon {
  margin: 16px 16px 16px 0;
}
