@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  position: absolute;
  bottom: -190px;
  left: 50px;
  z-index: 9999;
}

.infoBoxWithTopArrow {
  max-width: 450px;
  padding: 1rem;
  background-color: var.$white;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;
}

.infoBoxWithTopArrow::before {
  content: "";
  position: absolute;
  left: 60px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: var.$white;
}

.closeButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 13px;
  color: var.$warmGray7;
}

@include mq.mobile {
 .root {
  bottom: -115px;
  left: 105px; 
 } 
}