@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.container {
  max-width: var.$wideDesktopWidth;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
}

.root {
  background-color: var.$black;
}

.root.isCoastal {
  background-color: var.$navyBlue1;
}

.textContainer {
  margin-top: auto;
  margin-bottom: auto;
  padding: 40px 16px;
  color: var.$white;
}


.title {
  margin-top: 24px;
  margin-bottom: 44px;
}

.infoContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 16px;
}

.linkStyledAsOverline {
  display: flex;
  align-items: center;
  color: var.$white !important;
  text-transform: uppercase;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}

.imageContainer {
  grid-row: 1/2;
  margin: 0;
}

.mapContainer {
  margin: 4rem 0 0 0;
}

.map {
  background: var.$white;
}

@media (max-width: 767px) {
  .title {
    margin-block: 8px;
  }
}

@include mq.tablet {
  .textContainer {
    padding: 2rem;
  }

  .infoContainer {
    gap: 36px;
    margin-top: 0;
  }

  .container {
    grid-template-columns: 1.25fr 1fr;
  }
}

@include mq.laptop {
  .textContainer {
    padding: 0;
  }

  .image {
    max-height: unset;    
  }

  .imageContainer {
    grid-column: 2/3;
  }

  .map {
    height: 100%;
  }

  .container {
    max-width: var.$desktopContainerWidth;
    gap: 56px;
    min-height: 432px;
    padding-left: 0;
  }

  .container.isBookingFunnel {
    max-width: var.$wideDesktopWidth;
    padding-left: 32px;
  }
}

