@use '/src/style/variables.scss' as var;

// mainHeader
.mainHeader{
  .container {
    width: 100%;
    display: flex;
    position: absolute; 
    overflow: visible;
    top: 0;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: unset;
    z-index: 1002;
  }

  .fixed {
    position: fixed;
  }

  .hasAlertBanner {
    flex-wrap: wrap;
  }

  .isFilledHeader {
    background-color: var.$white; 
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 2;
  }

  .header {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    width: var.$desktopContainerWidth;
    justify-content: flex-start;
  }

  .navigation {
    display: flex;
    width: 100%;
  }

  .logoContainer {
    padding: 24px 15px 18px 0;
    display: flex;
    align-content: center;
    align-items: center;
  }

  .logo {
    display: flex;
  }

  @media (min-width: var.$breakpoint-tablet) {
    .header {
      width: var.$desktopContainerWidth;
    }
  }
}

.contentContainer{
  display: flex;
  justify-content: space-between;
  max-width: var.$desktopContainerWidth;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.imgContainer{
  display: flex;
  justify-content: space-between;
}
.imgContainer > *:not(:last-child) {
  margin-right: 10px; 
}
// FocalPointImage
.focalPointImage {
  position: relative;
  overflow: hidden;
}

@media (max-width: 600px) {
  .focalPointImage {
    width: inherit;
    object-fit: cover;
  }
}

.coverImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}