@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.signupLoginButtonGroup {
  display: flex;
  
  .buttonContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    flex: 2;
  }
  
  .mobileButtonContainer {
    width: 192px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .linkText {
    font-weight: 500;
    color: var.$white;
    font-size: 16px!important;
    text-decoration: none!important;
  }
  
  .linkText:hover {
    text-decoration: underline;
  }
  
  .isHeader {
    color: var.$black;
  }
}

@include mq.tablet {
  .signupLoginButtonGroup {
    flex:2;
  }
}