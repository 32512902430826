/* This animation is disabled, because it is not working because of top's image desgin of landing page. 
If you are fixing the current solution, it will be working. */

#animatedMobileHeader {
  /* animation: fadein 900ms ease-in alternate; */
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}


/* .fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
} */