@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.productDescriptionSection {
  max-width: 1130px;
  border: 1px var.$black;
  box-shadow: 1px 1px 5px 0px var.$grey4;
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  border-radius: 4px;
  background-color: var.$white;
  flex-direction: column-reverse;
}

.productDescriptionImage {
  width: 100%;
}

.productDescription {
  row-gap: 1.5rem;
  color: var.$black;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.productDescriptionText {
  color: var.$black;
  margin-top: 1rem;
  margin-bottom: 12px;
}

.linkStyle {
  display: flex;
  align-self: flex-start;
  color: var.$black !important;
}

.imageContainer {
  width: 100%;
}

.focalStyles {
  margin-left: 1px;
  border-radius: 4px;
}


@include mq.tablet {
  .productDescriptionWithImage,
  .imageContainer,
  .productDescriptionImage {
    width: 50%;   
  } 

  .productDescriptionImage {
    flex: 1;
  }

  .productDescriptionSection {
    flex-direction: row;
  }
}