@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  max-width: var.$desktopContainerWidth;
  margin: 0 16px;
}

.resultSummary {
  color: var.$textBlack;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.filterContainer {
  width: 100%;
  margin-bottom: 20px;
}

.infoBox {
  width: 100%;
}

@include mq.tablet {
  .titleContainer {
    flex-direction: row;   
  } 
}