@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 974px;
}

.listItem {
  border-radius: 8px;
  border: 3px solid var.$warmGray2;
}

.listItemActive {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border: none !important;
}

.accordionTrigger {
  display: flex;
  justify-content: space-between;
  border: none;
  background: var.$warmGray2;
  color: var.$warmGray6;
  font-size: 1rem;
  margin: 0;
  padding: 1em 1.5em;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-weight: 700;

  .edit {
    font-weight: 700;
  }
}

.accordionTrigger:focus {
  border: 2px solid var.$icyBlue;
  border-radius: 8px;
}

.accordionTrigger:enabled {
  cursor: pointer;
}

.accordionTrigger[aria-expanded="true"] {
  color: var.$textBlack;
  background-color: var.$white;
  border-radius: 8px;
  text-transform: uppercase;
}

.accordionTrigger:disabled {
  color: var.$warmGray4;
  cursor: not-allowed;
}

.accordionPanel {
  background-color: var.$white;
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: .5rem;
  @include mq.desktop {
    padding: .5rem 1.5em 1.5em 1.5em;
  }
}

.accordionPanel[hidden] {
  display: none;
}
