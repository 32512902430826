@use '/src/style/variables.scss' as var;

.linebreak {
  width: 100%;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: var.$volcanoBlack4;
}

.linebreak.hasMargin {
  margin: 15px 0;
}