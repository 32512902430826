@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.contentContainer {
  margin-top: 20px;
}

.root h2 {
  margin-bottom: 24px;
}

.root h2 + p {
  margin-bottom: 24px;
}

.root table {
  border-spacing: 0;
  border-collapse: collapse;
}

.root td {
  padding: 10px 20px 10px 24px;
}

.root th {
  text-align: left;
  padding: 15px 0 15px 24px;
}

.root tbody tr:nth-child(odd) {
  background-color: var.$warmGray1;
  border-bottom: 4px solid white;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}

.bottomContainer > p {
  margin-bottom: 12px;
}

.contactEmail {
  font-weight: 500;
}

.headerRadioButton {
  padding-right: 62px!important;
}

.radioButtonGroup span {
  box-sizing: border-box;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.buttonContainer {
  margin-right: 20px;
}

@media (max-width: var.$breakpoint-tablet) {
  .root .checkmark {
      margin-right: 0!important;
  }

  .root table {
      width: 100%;
      margin: 0 auto;
  }

  .root table th {
      display: none;
  }

  .root table tr > td:first-child {
      background-color: var.$warmGray3;
  }

  .root table tr:not(:first-child) {
      border-bottom: 3px solid var.$warmGray2;
      display: block;
      margin-bottom: .625em;
      width: 100%;
  }

  .root table td {
      border-bottom: 1px solid var.$warmGray2;
      display: flex;
      justify-content: space-between;
      font-size: .8em;
  }

  .root table td:first-child::before {
      color: var.$warmGray7;
  }

  .root table td::before {
      content: attr(data-label);
      color: var.$warmGray6;
      font-weight: bold;
      text-transform: uppercase;
      width: 100px;
      text-align: left;
  }

  .root table td:last-child {
      border-bottom: 0;
  }
}
