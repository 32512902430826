@use '/src/style/variables.scss' as var;

.table {
  width: 100%;
  background-color: var.$volcanoBlack3;
  border-collapse: separate;
  border-spacing: 2px;
  height: fit-content;
}

.tableHeader {
  white-space: nowrap;
  background-color: var.$white;
}

.tableBody {
  border-top: 15px solid transparent;
  padding: 12px;
}

.tableHeadline {
  padding-left: 40px;
  text-align: left;
}

.accessibilityLabel {
  display: flex;
  color: var.$textBlack !important;
  padding: 12px 0;
  justify-content: center;
}
