@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonContainer {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
