@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;


//noBookings
.description {
  text-align: center;
  color: var.$warmGray6;
  margin-bottom: 20px;
  max-width: 450px;
}

.noResultRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  margin: 0 auto;
  flex: 1;
  justify-content: center;
}

.fullHeightRoot {
  height: 45vh;
}

.exploreButton {
  height: 56px;
}

.button {
    min-width: 250px!important;
    min-height: 44px!important;
}

.icon {
  margin-bottom: 20px;
}
  
  /* Apply tablet media query */
  @include mq.tablet {
    .noResultRoot,
    .description {
      max-width: var.$breakpoint-tablet;
    }
  }
