@use '/src/style/variables.scss' as var;

.title {
  width: 70%;
  height: 30px;
}

.image {
  width: 100px;
  height: 200px;
}

.paragraph {
  display: grid;
  gap: 0.5rem;
}

.paragraph > div {
  height: 20px;
}

.paragraph > div:first-child {
  width: 85%;
}

.button {
  width: 100%;
  height: 38px;
}

.shimmer {
  position: relative;
  display: inline-block;
  background: linear-gradient(
      to right, 
      var.$volcanoBlack3,
      var.$grey1,
      var.$volcanoBlack3
  ) 8% 18% 33%;
  background-size: 800px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  animation: shimmer 1s forwards infinite linear;
}

@keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}
