.title {
  font-weight: bold;
  margin-bottom: 8px;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}