@use '/src/style/variables.scss' as var;

/* Styles.module.css */

.root {
  border-radius: inherit;
  margin: -24px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 32px;
  border: none;
  background: none;
}

.headerSection {
  padding: 64px;
  border-radius: inherit;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
  flex-direction: column;
  background-color: var.$justGreen;
  color: var.$white;
}

.headerSectionAlert {
  background-color: var.$hurtigrutenRed4;
}

.contentSection {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 4em;
  flex-direction: column;
  padding: 32px 25px;
}
