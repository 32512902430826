@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

//PromotionsList
.promotionsList {
  width: 100%;
  margin-block: 0;
  padding-inline: 0;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include mq.mobile {
    flex-direction: row;
  }
}

//PromotionsHeader

.promotionsHeader{
    margin-top: 4rem;

  .date {
    margin-top: 20px;
  }
  
  .title {
    margin-top: 40px;
  
    @include mq.mobile {
      margin-top: 24px;
    }
  }
  
  .descriptionSection {
    display: flex;
    flex-wrap: wrap;
  
    @include mq.tablet {
      flex-wrap: nowrap;
    }
  }
  
  .descriptionContent {
    margin-top: 24px;
  }
}

//promotionIncludedSailings

.promotionIncludedSailings{
    display: grid;
    row-gap: 24px;
  
  .dropdownSection {
    width: fit-content;
    min-width: 343px;
  }
  
  .includedSailingsList {
    display: grid;
    row-gap: 24px;
  }
  
  .includedSailingsCard {
    display: flex;
    border: solid 1px var.$black;
    flex-direction: column;
  
    @include mq.tablet {
      flex-direction: row;
    }
  }
  
  .includedSailingsCardImageContainer {
    height: 100%;
    min-height: 338px;
    min-width: 338px;
    flex: 1;
    position: relative;
  }
  
  .includedSailingsCardInfoContainer {
    padding: 1rem;
    width: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  
    @include mq.mobile {
      padding: 2rem;
    }
  }
  
  .includedSailingsCardInfo {
    display: grid;
    row-gap: 0.5rem;
  
    @include mq.mobile {
      row-gap: 1rem;
    }
  }
  
  .includedSailingsUsp {
    margin: 0;
  }
  
  .includedSailingsCardImage {
    height: 100%;
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;
    padding: 1px;
  }
  
  .includedSailingsButtonSection {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  
    @include mq.mobile {
      justify-content: flex-end;
    }
  }
  
  .seeMoreButtonSection {
    display: flex;
    justify-content: center;
  }
  
  .tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem 1rem;
    background-color: var.$frozenBlue;
  }
  
  .button {
      min-width: 320px;
  }
}