@use '/src/style/variables.scss' as var;

.arrowIcon {
  margin-left: 8px;
  margin-top: 3px;
}

.sortingContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 224px;
}

.sortingLabel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  div > p {
    font-size: 16px!important;
  }
}

.sortingRadioContainer {
  margin-top: 32px;
  padding: 16px;
  width: 240px;
  background-color: var.$white;
  box-shadow: 1px 4px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: absolute;
}

.sortingTitle {
  margin-left: 8px;
}

.label {
  font-weight: 500;
}

@media (max-width: 800px) {
  .sortingContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .sortingTitle {
    display: none;
  }
  .sortingRadioContainer {
    display: none;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
