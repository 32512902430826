// breakpoints
$breakpoint-mobile: 375px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1170px;
$breakpoint-desktop: 1440px;
// sizes
$contentPaddingBottom: 150px;
$desktopContainerWidth: 1136px;
$wideDesktopWidth: 1440px;
$articleContainerSize: 752px;
$footerHeight: 426px;
$headerHeight: 73px;
// colors
$black: #000000;
$greyDark: #3a3a3a;
$clearBlue: #D1EDFE;
$textBlack: #1D1D1D;
$white: #FFFFFF;
$red: #D40000;
$darkRed: #7F0C07;
$lightRed: #FDE3E1;
$snowBlue: #D1F3FE;
$frozenBlue: #A0DBEF;
$snowYellow: #FFF4D1;
$penguinYellow: #F8CF5A;
$warmGray1: #F8F8F8;
$warmGray2: #E5E3E3;
$warmGray3: #D1CECC;
$warmGray4: #B0AEAC;
$warmGray5: #656664;
$warmGray6: #3B3B3A;
$warmGray7: #3B3B3A;
$warmBrown: #462312;
$midnightYellow1: #FFF1D1;
$midnightYellow3: #FDD24E;
$midnightYellow5: #E76A1E;
$warmRed1: #FDAAA6;
$warmRed2: #E6716E;
$expeditionJungleGreen1: #6DC88D;
$hurtigrutenRed1: #E01C1F;
$hurtigrutenRed2: #E94444;
$hurtigrutenRed3: #E01C1F;
$hurtigrutenRed4: #FE8787;
$hxBlue1: #374d67;
$hxBlue2: #202835;
$icyBlue: #518BAC;
$clearGreen: #C9FED5;
$snowGreen: #169D3B;
$justGreen: #A9C15E;
$mossGreen: #F2F7E8;
$frozenGreen: #A5C480;
$lightGreen: #E0FBC8;
$brightGreen: #20CE57;
$snowOrange: #D87035;
$snowNo: #F2F0AF;
$lightBlue: #E8F3FD;
$foggyBlue1: #E8EDF1;
$foggyBlue2: #CFDAE3;
$brightBlue: #47AFFC;
$clearBlue2: #8DD3FE;
$clearBlue3: #3eaceb;
$grey1: #E6E6E6;
$grey2: #CCCCCC;
$grey3: #999999;
$grey4: #666666;
$grey5: #333333;
$grey6: #e4e4e7;
$redRegular: #E21710;
$greyDark: #f3f0ee;
$navyBlue1: #092C55;
$navyBlue2: #174C8B;
$navyBlue3: #47A4D9;
$navyBlue4: #67BDE2;
$navyBlue5: #276FB2;
$navyBlue6: #051D39;
$clearBlue1: #0F4FA8;
$clearBlue2: #2B87D7;
$redDark: #af0a0f;
$menu: #0b233f;
$volcanoBlack1: #494949;
$volcanoBlack2: #B3B3B3;
$volcanoBlack3: #F5F5F5;
$volcanoBlack4: #CCCCCC;
$volcanoBlack7: #383838;
$volcanoBlack8: #272727;
$volcanoBlack9: #1A1A1A;
$greyRegular: #dfdfdf;
$redDark: #B10400;
$redRegular: #cf3425;