@use '/src/style/variables.scss' as var;

// TabSecondary
.tabSecondaryContainer {
  width: '100%'
}

// TabSecondaryHeader
.tabSecondaryHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .scrollX {
    overflow-x: hidden !important;
  }

  .headerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }

  .headerContentContainer {
    max-width: var.$desktopContainerWidth;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    background-color: var.$warmGray2;
    border-radius: 32px;
    padding: 4px;
    gap: 4px;
  }

  @media (max-width: 600px) {
    .headerContentContainer {
      flex-wrap: wrap;
      width: fit-content;
      justify-content: center;
      border-radius: 24px;
    }
  }

  .tabHeaderItem {
    color: var.$black;
    cursor: pointer;
    padding: 8px 24px;
    border: none;
    border-radius: 32px;
    outline: none;
    background-color: transparent;
  }

  .tabHeaderItem:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .tabHeaderItem:focus-visible {
    box-shadow: 0 0 0 2px var.$black;
  }

  .tabHeaderItemActive {
    background-color: var.$white;
  }

  .tabHeaderText {
    font-size: 0.875rem;
    font-size: 1rem;
  }
}