@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.videoContainer {
  width: 100%;
  height: 300px;
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
}

.blackOpacityOverlay {
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.9999999;
}

.videoGradient {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 300px;
  position: absolute;
  z-index: 2;
}

.overlayContainer {
  text-align: left;
  color: var.$white;
  width: 100%; 
  height: 300px;
  margin: auto;
}

.textOverlay {
  position: absolute;
  padding-top: 64px;
  margin: 0 10px;
  width: calc(100% - 20px);
}

.hero {
  width: inherit;
  object-fit: cover;
  height: 300px;
}

.videoHero {
  object-fit: cover;
}

.overlayPageTitle {
  color: var.$white;
  margin-bottom: 16px;
}

.overlayTitle {
  margin-bottom: 8px;
  color: var.$white;
}

@include mq.laptop {
  .overlayContainer {
    width: var.$desktopContainerWidth;
    max-width: var.$desktopContainerWidth;
  }  

  .textOverlay {
    width: 568px;
    margin: 0;
  }

  .hero {
    width: 100%;
  }

  .videoGradient {
    width: 100%;
  }
}
