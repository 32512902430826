@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/mixins/truncateLines.scss' as truncateLines;
@use '/src/style/variables.scss' as var;

// ZigZagArticleContainer
.titleClassName {
  text-align: left;
}

.zigZagArticleContainer {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 32px;
  width: 100%;
  
  @include mq.tablet {
    width: 100%;
    gap: 64px;
  }

  @include mq.desktop {
    margin-top: 0
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    @include mq.tablet {
      flex-direction: row;
      width: 100%;
    }
  }
  
  .itemReverse {
    @include mq.tablet {
      flex-direction: row-reverse;
    }
  }
  
  .imageContainer {
    height: 408px;
    overflow: hidden;
    position: relative;
    flex: 2;
    width: 100%;
    
    @include mq.tablet {
      max-height: 343px;
    }
  }
  
  .textContainer {
    flex: 1;
  }
  
  .button {
    width: fit-content;
    margin-top: 32px;
  }
  
  .buttonContent {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
  }
  
  .title {
    margin: 32px 0 24px;
  }
}

// gridArticleContainer
.gridArticleContainer {
  margin-top: 64px;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  @include mq.tablet {
    grid-template-columns: repeat(6, 1fr);
    margin-top: 0;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    grid-area: initial
  }

  @include mq.tablet {
    .gridOneItem {
      grid-area: 1 / 1 / 2 / 7;
    }

    .gridTwoItems {
      &:first-child {
        grid-area: 1 / 1 / 2 / 4;
      }
      
      &:last-child {
        grid-area: 1 / 4 / 2 / 7;
      }
    }

    .gridThreeItems {
      &:first-child {
        grid-area: 1 / 1 / 2 / 3;
      }
      
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }
      
      &:last-child {
        grid-area: 1 / 5 / 2 / 7;
      }
    }

    .gridFourItems {
      &:first-child {
        grid-area: 1 / 1 / 2 / 4;
      }
      
      &:nth-child(2) {
        grid-area: 1 / 4 / 2 / 7;
      }
      
      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 4;
      }
      
      &:last-child {
        grid-area: 2 / 4 / 3 / 7;
      }
    }

    .gridFiveItems {
      &:first-child {
        grid-area: 1 / 1 / 2 / 3;
      }
      
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }
      
      &:nth-child(3) {
        grid-area: 1 / 5 / 2 / 7;
      }
      
      &:nth-child(4) {
        grid-area: 2 / 1 / 3 / 4;
      }
      
      &:last-child {
        grid-area: 2 / 4 / 3 / 7;
      }
    }
}

  .linkText {
    font-size: 16px !important;
  }
  
  .textContainer {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .imageContainer {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-height: 343px;
    
    @include mq.tablet {
      height: 350px;
      max-height: unset;
    }
  }
  
  .text {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.image {
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.truncate {
  @include truncateLines.truncateLines;
}