@use '/src/style/mixins/mediaQueries.scss' as mq;

// Austin - serif font
// 700
@font-face {
  font-family: 'Austin';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src:
    url('/public/fonts/Austin-Bold-Web.woff2') format('woff2'),
    url('/public/fonts/Austin-Bold-Web.woff') format('woff');
}

// 600
@font-face {
  font-family: 'Austin';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src:
    url('/public/fonts/Austin-Semibold-Web.woff2') format('woff2'),
    url('/public/fonts/Austin-Semibold-Web.woff') format('woff');
}

// Atlas Grotesk - sans serif
// 400
@font-face {
  font-family: 'Atlas Grotesk';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url('/public/fonts/AtlasGrotesk-Regular-Web.woff2') format('woff2'),
    url('/public/fonts/AtlasGrotesk-Regular-Web.woff') format('woff');
}

// 500
@font-face {
  font-family: 'Atlas Grotesk';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src:
    url('/public/fonts/AtlasGrotesk-Medium-Web.woff2') format('woff2'),
    url('/public/fonts/AtlasGrotesk-Medium-Web.woff') format('woff');
}

// Austin 48/72
@mixin display {
  font-family: 'Atlas Grotesk', Austin, Georgia, sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 0.9;
  padding: 0;

  @include mq.tablet {
    font-size: 4.5rem;
    line-height: 0.8;
  }
}

// Austin 36/42
@mixin headline-1 {
  font-family: 'Atlas Grotesk', Austin, Georgia, sans-serif;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 0.9;
  padding: 0;

  @include mq.tablet {
    font-size: 2.625rem;
  }
}

// Austin 30/35
@mixin headline-2 {
  font-family: 'Atlas Grotesk', Austin, Georgia, sans-serif;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 1.2;
  padding: 0;

  @include mq.tablet {
    font-size: 2.1875rem;
  }
}

// Austin 24/28
@mixin headline-3 {
  font-family: 'Atlas Grotesk', Austin, Georgia, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2;
  padding: 0;

  @include mq.tablet {
    font-size: 1.75rem;
  }
}

// Austin 21/24
@mixin headline-4 {
  font-family: 'Atlas Grotesk', Austin, Georgia, sans-serif;
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.2;
  padding: 0;

  @include mq.tablet {
    font-size: 1.5rem;
  }
}

// Atlas Grotesk 18/20
@mixin headline-5 {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.35;
  padding: 0;

  @include mq.tablet {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

// Atlas Grotesk 16/18
@mixin headline-6 {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -1px;
  line-height: 1.5;
  padding: 0;

  @include mq.tablet {
    font-size: 1.125rem;
    letter-spacing: 0px;
    line-height: 1.4;
  }
}

// Atlas Grotesk 16/18
@mixin headline-6-medium {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -1px;
  line-height: 1.5;
  padding: 0;

  @include mq.tablet {
    font-size: 1.125rem;
    line-height: 1.35;
  }
}

@mixin default {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.35;
  margin: 0;
  padding: 0;
}

@mixin body {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.35;
  padding: 0;

  @include mq.tablet {
    font-size: 1rem;
    line-height: 1.35;
  }
}

@mixin body-medium {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4;

  @include mq.tablet {
    font-size: 1rem;
    line-height: 1.35;
  }
}

@mixin body-small {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5;

  @include mq.tablet {
    font-size: 0.875rem;
    line-height: 1.55;
  }
}

@mixin body-small-medium {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5;

  @include mq.tablet {
    font-size: 0.875rem;
    line-height: 1.55;
  }
}

// 15 px custom font used
// for different ui stuff
@mixin body-ui {
  font-size: 0.8125rem;
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;

  line-height: 1;
  @include mq.tablet {
    font-size: 0.9375rem;
  }
}

@mixin overline {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.35;
  text-transform: uppercase;
}

@mixin caption {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5;
}

@mixin caption-medium {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5;
}

// UI-text is the same as caption

@mixin ui-text {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5;
}

@mixin ui-text-bold {
  font-family:
    Atlas Grotesk,
    sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5;
}
