@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0;
}

@include mq.laptop {
  .root {
      gap: 32px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

