@use '/src/style/variables.scss' as var;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 16px;
  text-align: center;
  background-color: var.$warmGray1;
}

.title {
  margin: 0 0 16px 0;
  color: var(--warmGray7);
}

.descContainer {
  width: 100%;
  max-width: 425px;
  text-align: center;
}

.desc {
  margin: 10px 0 0 0;
  color: var.$warmGray6;
}

.subTitle {
  margin: 32px 0 16px 0;
  align-items: center;
  color: var.$warmGray5;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 560px;
}

.buttonContainerCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 560px;
  margin-top: 32px;
}

.button {
  width: 176px;
  min-height: 56px;
}

.buttonCenter {
  width: 272px;
  padding: 16px;
}
