@use '/src/style/variables.scss' as var;

.tableCellButton {
  border: none;
  font: inherit;
  outline: inherit;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  box-sizing: border-box;
  min-width: 8rem;
  position: relative;
  color: var.$black;
}

.tableCellButton.isSoldOut {
  color: var.$warmGray5;
  background-color: var.$grey1;
}

.tableCellButton.hasMaxPaxNotification:not(.isSoldOut) {
  background-color: var.$midnightYellow1;
  cursor: not-allowed;
}

.tableCellButton.active:hover {
  background: var.$volcanoBlack4;
}

.tableCellButton.hasMaxPaxNotification {
  cursor: not-allowed;
}

.tableCellButton.hasMaxPaxNotification.isSoldOut,
.tableCellButton.hasMaxPaxNotification,
.tableCellButton.isSoldOut {
    gap: 16px;
}

.alertMessageContainer {
  width: 100%;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.discount {
  height: 1.5rem;
}

.disabledButtonLabel {
  max-width: 6rem;
}