@use '/src/style/variables.scss' as var;

.root {
  width: 100%;
  height: 100%;
  border: 1px solid var.$black;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Atlas Grotesk Medium', sans-serif;
  padding: 4px;
  background-color: transparent;
}

.root:hover {
  color: var.$white;
  border-color: var.$black;
  background-color: var.$black;
}

.small {
  width: 140px;
  height: 44px;
}

.isCoastal {
  border: 1px solid var.$navyBlue2;
  color: var.$navyBlue2;
}

.isCoastal:hover {
  border-color: var.$navyBlue2;
  background-color: var.$navyBlue2; 
}