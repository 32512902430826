@use '/src/style/variables.scss' as var;

/* Styles.module.css */

.content {
  margin-top: 72px;
  min-height: 70vh;
  background-color: var.$white;
}

.content.isGreyLayout {
  background-color: var.$volcanoBlack3
}

.contentLandingPage {
  margin-top: 0;
  min-height: 100vh;
}

.authMobileHeader {
  display: none;
}
.hasAlertBannerOnLandingPage {
  margin-top: 0px;
}
@media (min-width: 800px) {
  .hasAlertBannerOnLandingPage {
    margin-top: 100px;
  }
}


@media (max-width: 1000px) {
  .authMobileHeader {
      display: initial;
  }
}

.authDesktopHeader {
  display: none;
}

@media (min-width: 1000px) {
  .authDesktopHeader {
      display: initial;
  }
}

.mobileHeader {
  display: none;
}

@media (max-width: 800px) {
  .mobileHeader {
      display: initial;
  }
}

.desktopHeader {
  display: none;
}

@media (min-width: 800px) {
  .desktopHeader {
      display: initial;
  }
}
