@use '/src/style/variables.scss' as var;

.text {
  text-align: center;
  color: var.$warmGray6;
  max-width: 450px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 50px;
}

.iconContainer {
  margin-bottom: 30px;
}
