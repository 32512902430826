@use '/src/style/variables.scss' as var;

.container {
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding-top: 64px;
  padding-right: 16px;
  padding-left: 16px;
  box-sizing: border-box;
}

.title {
  font-family: 'Atlas Grotesk Medium', sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: var.$warmGray7;
  margin: 15px;
}

.alertIcon {
  width: 85px;
  height: 85px;
  margin: 15px;
}

.description {
  max-width: 432px;
  width: 100%;
  height: 72px;
  font-family: 'Atlas Grotesk Regular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var.$warmGray6
}

.refreshButtonContainer {
  margin-top: 25px;
}

.refreshButtonContainer button {
  width: 160px;
  height: 55px;
}
