@use '/src/style/variables.scss' as var;

.container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  background-color: var.$clearBlue;
  border: 1px solid var.$clearBlue;
  color: var.$black;
  display: flex;
  height: 16px;
  justify-content: center;
  padding: 2px;
  width: 16px;
}