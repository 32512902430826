@use '/src/style/variables.scss' as var;

.container {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .container {
    gap: 12px;
  }
}

.button {
  display: flex;
  gap: 6px;
  padding: 14px 30px;
  text-decoration: none;
  font-family: 'Atlas Grotesk', sans-serif;
  font-weight: 500;
  line-height: 1.4;
  border: 2px solid;
  font-size: 14px;
}

.button:hover {
  cursor: pointer;
}

.active {
  color: var.$white;
  background-color: var.$clearBlue1;
  border-color: var.$clearBlue1;
}

.active:hover {
  background-color: var.$clearBlue2;
  border-color: var.$clearBlue2;
}

.unactive {
  color: var.$clearBlue1;
  background-color: var.$white;
  border-color: var.$clearBlue1;
}

.unactive:hover {
  color: var.$white;
  background-color: var.$clearBlue2;
  border-color: var.$clearBlue2;
}

.disabled {
  color: var.$warmGray6;
  background-color: var.$white;
  border-color: var.$warmGray5;
}
