@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.voyageDropdown > div select,
.itineraryDropdown > div select,
.departureDropdown > div button,
.p2p > div select {
  border: 1.4px solid var.$warmGray2;
}

.itineraryDropdown,
.vehicleDropdown,
.departureDropdown,
.shipDropdown {
  margin-top: 24px;
}

.departureDropdown > div {
  height: fit-content;
}

.capSelector {
  margin-top: 24px;
}

.capSelector > div div button {
  border: none;
}

.p2p > div div button,
.shipDropdown > div select,
.vehicleDropdown > div button,
.departureDropdown > div button {
  border: none;
}

.shipVehicleContainer {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.shipVehicleContainer > div {
  width: 100%;
}

.submitButton {
  display: block;
  width: 100%;
  align-self: flex-end;
}

.searchButtonContainer {
  text-align: right;
  margin-top: 34px;
}

@include mq.mobile {
  .vehicleDropdown {
    flex-grow: 0;
    flex-basis: 50%;
    min-width: 0;
  }

  .shipDropdown {
    flex-grow: 0;
    flex-basis: 100%;
    min-width: 0;
  }

  .allowVehicleBooking {
    flex-basis: 50%;
  }

  .shipVehicleContainer {
    flex-wrap: nowrap;
  }

  .shipVehicleContainer > div {
    flex: 1;
  }

  .submitButton {
    width: 50%;
  }
}
