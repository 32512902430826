@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.emailVerification {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;

  @include mq.laptop {
    position: relative;
    top: -50px;
    z-index: 1000;
    background-color: var.$white;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.button {
  width: 120px;
}

.inputField {
  &.inputField { // Increased specificity similar to '&&'
    border-radius: 8px;
  }
}

.buttonCntr {
  width: 100%;
  display: flex;
  justify-content: end;
}

.mainCntr {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  @include mq.laptop{
    gap: 120px;
    flex-direction: row;
  }
}

.rightCntr {
  align-items: center;
  border-top: 1px solid var.$warmGray2;
  margin-left: 0;
  margin-top: 25px;

  @include mq.laptop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    margin-left: 25px;
    margin-top: 0;
    padding-bottom: 25px;
    border-top: none;
  }
}

.formContainer {
  width: 100%;
}

.inputContainer {
  min-height: 100px;
}

.desc {
  color: var.$textBlack;
  margin-bottom: 0;

  @include mq.tablet{
    margin-bottom: 50px;
  }
}

.leftCntr {
   margin-top: 50px;
   padding: 16px;

   @include mq.mobile {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0;
    padding: 0;
  }
}

.mandatoryField {
  color: var.$textBlack;
  margin-top: 10px;
}

.inputCntr {
  max-width: 100%;

  @include mq.mobile {
    max-width: 457px;
    margin-bottom: 50px;
    width: 100%;
  }
}