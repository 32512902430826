@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.root {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var.$black;
  color: var.$volcanoBlack3;
}

.root a {
  line-height: 28px;
  color: var.$volcanoBlack3;
}

.root a:hover {
  color: var.$warmGray3;
}

.container {
  display: grid;
  justify-content: space-between;
  max-width: 1136px;
  width: 100%;
  padding: 2rem 1rem 4rem 1rem;
  gap: 32px;
}

.column {
  display: grid;
  flex: 1;
  justify-content: space-between;
  gap: 40px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.soMe {
  display: flex;
  justify-content: space-between;
  gap: 17px;
  padding: 0.5rem 0;
}

.buttonAsLink {
  background: none;
  padding: 0;
  border: none;
  color: var.$white;
  text-decoration: underline;
  font-family: 'Atlas Grotesk', sans-serif;
  font-size: 0.875rem;
  cursor: pointer;
}

.buttonAsLink:hover {
  color: var.$warmGray4;
}

.awardsContainer {
  display: flex;
  justify-content: center;
  background-color: var.$grey1;
}

.awards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 30px 0;
  width: var.$desktopContainerWidth;
}

@media (max-width: var.$breakpoint-tablet) {
  .awards {
      width: 100%;
      max-width: 100vw;
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
  }
}

@include mq.tablet {
  .container {
    display: flex;
  } 
}