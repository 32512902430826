@use '/src/style/variables.scss' as var;

.searchHeader {
  display: flex;
  flex: 6;
  flex-direction: column;
  background-color: var.$white;
  justify-content: flex-start;
  width: 100%;
  margin-top: 25px;
}

.desc {
  color: var.$warmGray6;
}

.readMore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.linkText {
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.uspListElement {
  display: flex;
  align-items: flex-start;
  padding: 5px 0 5px 10px;
  width: 50%;
  box-sizing: border-box;
}

.iconsContainer {
  display: flex;
  width: 100%;
  margin-top: 29px;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.iconCntr {
  width: 35px;
  height: 35px;
  display: flex;
  min-width: 35px;
  min-height: 35px;
}

.root {
  display: flex;
  width: 100%;
}

.showIcon {
  margin-left: 8px;
  color: var.$red;

  &.hx {
    color: var.$hxBlue1;
  }
}

@media (max-width: 600px) {
  .iconsContainer {
    flex-direction: column;
  }
  .uspListElement {
    width: 100%;
  }
  .showIcon {
    display: none;
  }
}

@media (min-width: 601px) {
  .iconsContainer {
    flex-direction: row;
  }
  .uspListElement {
    width: 50%;
  }
}
