@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.article {
  box-sizing: border-box;
  transition: all 0.3s;
  border: solid 1px var.$warmGray2;
  cursor: pointer;
  background-color: var.$white;
  width: 100%;
  height: 200px;
}

.article svg {
  transition: all 0.3s;
}

.article:hover {
  border: solid 1px var.$red;

  &.hx {
    border: solid 1px var.$hxBlue1;
  }
}

.article:hover svg {
  transform: translate(8px, 0px);
}

.link {
  color: inherit;
  text-decoration: inherit;
  display: grid;
  row-gap: 8px;
  padding: 16px;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: center;
}

.description {
  color: var.$warmGray6;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  font-size: 14px;
}

@include mq.mobile {
  .article {
    width: 272px;
  }
}