@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roote.displayP2PResult {
  border-radius: 10px !important;
}

.departureInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 2px solid var.$volcanoBlack3;
  padding: 16px;
}

.priceAndButtonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  row-gap: 10px;
  column-gap: 8px;
  padding: 16px;
  justify-content: space-between;
}

.priceContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.price {
  font-weight: 700!important;
  font-size: 18px!important;
}

.perPerson {
  text-align: left;
  font-size: 12px;
}

.infoBox {
  margin: 5px 32px;
  background-color: var.$foggyBlue1 !important;
  border: solid 1px var.$foggyBlue2 !important;
  padding: 16px;
  font-weight: 400;
}

.infoBoxWarning {
  margin: 5px 32px;
  padding: 16px;
  font-weight: 400;
}

@include mq.mobile {
  .departureInfoContainer {
    border-bottom: none;
    padding: 20px 24px;
  }

  .priceAndButtonContainer {
    padding: 20px 24px;
    column-gap: 24px;
    flex-direction: row-reverse;
  }

  .priceContainer {
    gap: 20px;
  }

  .perPerson {
    text-align: right!important;
  }
}

@include mq.tablet {
  .root {
    flex-direction: row;
  }
}

@include mq.laptop {
  .root {
    margin-left: 12px;
    border-radius: 10px 0 0 10px;
  }

  .root.displayP2PResult {
    border-radius: 10px;
  }
}