@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

  .menuHeaderRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  height: 71px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  z-index: 999;
}

.menuHeaderRoot.fixed {
  position: fixed;
}

.menuHeaderRoot.isFilledHeader {
  background-color: var.$white; 
  border: 1px solid var.$warmGray2; 
}

.logoContainer {
  display: flex;
}

.menu {
  cursor: pointer;
  outline: none;
}

.contentRoot {
  top: 71px;
  width: 100%;
  display: flex;
  z-index: 9999;
  position: fixed;
  margin-top: -1px;
  align-items: flex-start;
  padding-left: 16px;
  border-bottom: 1px solid rgb(209, 206, 204);
  flex-direction: column;
  padding-bottom: 14px;
  justify-content: flex-start;
  background-color: white;
}

.contentRoot.fixed {
  position: fixed;
}

.alertBanner {
  position: relative;
  top: 71px;
  z-index: 100;
  width: 100%;
}

@include mq.desktop {
.alertBanner {
  position: absolute;
  top: 71px;
  z-index: 100;
  width: 100%;
}
}
.alertBanner > div {
  width: unset;
}


