@use '/src/style/variables.scss' as var;

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 1rem;
  padding: 0;
}

.listItem {
  display: flex;
  gap: 0.5rem;
}

.text {
  color: var.$warmGray6;
  fill: var.$warmGray6;
}

.isSelectedText {
  color: var.$white;
  fill: var.$white;
}