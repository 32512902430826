@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/typography.scss' as text;

.inputWrapper {
  height: 60px;
  padding: 0 1.25rem;
  border-radius: 0.5rem;
  background-color: var.$white;
  border: 1px solid var.$warmGray2;
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 0.5rem;
}

.input {
  @include text.body;
  font-size: 18px!important;
  padding: 0.5rem 0;
  outline: none;
  cursor: pointer;
  flex: 1;
  border: none;
  position: relative;

  line-height: unset !important;
  margin-bottom: unset !important;
}

.input:focus-visible {
  outline: none!important;
}

.inputPostIcon {
  height: 24px;
  fill: var.$navyBlue1;
}

.calendarContainer {
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  background-color: var.$white;
  border: 1px solid var.$warmGray2;
  outline: none;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  :global(.react-datepicker__header) {
    background: none;
    border: none;
  }

  :global(.react-datepicker__navigation) {
    margin-top: 0.75rem;

    ::before {
      border-width: 2px 2px 0px 0px;
      border-color: var.$red;
    }
  }

  :global(.react-datepicker__current-month) {
    padding: 0.5rem 0;
    @include text.ui-text;
    font-size: 18px;
    font-weight: 500;
    font-variant: small-caps;
  }

  :global(.react-datepicker__day-names) {
    @include text.body-small;
  }

  :global(.react-datepicker__day) {
    @include text.body-small;
    line-height: 1.5rem;
  }

  :global(.react-datepicker__day--outside-month) {
    opacity: 0.5;
  }

  :global(.react-datepicker__day--selected) {
    border-radius: 4px;
    border: 1px solid var.$red;
    vertical-align: middle;
    background-color: var.$white;
    color: var.$black;
  }
}