@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

// ExtraExcursionsItems
.extraExcursionsItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: none;
  padding: 0;

  &:first-child {
    margin-top: 8px;
  }

  .internalList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    padding: 0;
  
    @include mq.laptop {
      gap: 24px;
    }
  }
}

// NoAvailableExtraItem
.noAvailableExtraItem {
  display: flex;
  align-items: center;
  border: solid 1px var.$warmGray2;
  border-radius: 8px;
  padding: 2rem 1rem;
  @include mq.tablet {
    padding: '2.5rem 2rem',
  }

  .message {
    p {
      font-weight: 500;
    }
  }
}

// ExtrasSection
.extrasSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: none;
  padding: 0;

  .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

// IconList
.iconList {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
  margin: 16px 0 0 0!important;
  padding: 0!important;

  .icon {
    border: none;
    background-color: transparent;
    display: flex;
    cursor: pointer;
    padding: 0;
  }
  
  .iconItem {
    font-size: 14px;
    line-height: 1.14;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var.$warmGray6;
    box-sizing: border-box;
  }

  .isAdded {
    color: var.$white;
  }
}


// ExtraItem
.extraItem {
  border-radius: 8px;
  position: relative;

  .card {
    border: solid 1px var.$warmGray3;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    @include mq.tablet {
      flex-direction: row;
    }

    .cardAdded {
      border-bottom: solid 0px transparent;
      border-radius: 8px 8px 0 0 !important;
    }

    .bookedTravelersContainer {
      background-color: var.$frozenGreen;
      padding: 11px;
      display: flex;
      align-items: center;
      gap: 12px;
      position: absolute;
      right: 0;
      border-radius: 0 8px 0 0;
    }

    .imageContainer {
      display: flex;
      flex: 1;
      background-color: var.$foggyBlue1;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
    }

    .image {
      z-index: 2;
      width: 100%;
      height: auto;
      object-fit: cover;
    
      @include mq.tablet {
        height: 100%;
      }
    }

    .imagePlaceholder {
      z-index: 2;
      height: 64px;
      width: 77px;
    
      @include mq.tablet {
        height: 48px;
        width: 58px;
      }
    }

    .informationContainer {
      display: flex;
      flex: 4;
      flex-direction: column;
      flex-grow: 5;
      padding: 16px;
      min-height: 80px;
      gap: 24px;
      background-color: var.$white;
    
      @include mq.tablet {
        flex-direction: row;
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex: 5;
      gap: 8px;
    
      @include mq.tablet {
        gap: 4px;
      }
    }

    .extraType {
      p {
        font-size: 10px;
        opacity: 0.86;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        color: var.$white;
      }
    }
    
    .title {
      h4 {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: var.$white;
      }
    }
    
    .headerTitle {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    
    .descriptionContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 16px;
    }
    
    .description {
      font-family: 'Atlas Grotesk Regular', sans-serif;
      font-size: 14px;
      color: var.$warmGray6;
      line-height: 1.71;
      overflow: hidden;
      text-overflow: ellipsis;
    
      p {
        margin-bottom: 10px;
      }
    
      ul {
        padding: 0;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
    
      @include mq.tablet {
        align-items: flex-end;
        justify-content: flex-end;
      }
    }

    .buttonGreen {
      background-color: var.$white;
    }

    .showMoreButton {
      button {
        font-weight: 400;
        font-size: 14px;
        color: var.$textBlack;
      }
    }

    .flatButtonContainer {
      display: flex;
      margin-top: 4px;
    }
    
    .freeExcursion {
      p {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
      }
      color: var.$textBlack;
    }
    
    .bold {
      font-weight: 700;
    }
    
    .price {
      color: var.$textBlack;
    
      p {
        font-size: 14px;
      }
    }
    
    .priceCurrency {
      font-weight: 700;
      font-size: 16px;
      color: var.$textBlack;
    }

    .isAddedCoastal {
      background-color: var.$navyBlue2;
    }

    .isAddedHX {
      background-color: var.$textBlack;
    }

    .isAdded {
      color: var.$white;
    }

    .isAddedGreenButton {
      background-color: var.$frozenGreen;
    }
  }
}
