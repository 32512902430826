@use '/src/style/variables.scss' as var;

// LoadingGif
.loadingGif {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .image {
    width: 120px;
    height: auto;
  }

  .text {
    margin-top: -10px;
    color: var.$red;

    &.hx {
      color: var.$hxBlue1;
    }
  }

  .textGrey {
    color: var.$greyRegular;
  }
}

// LoadingComponent
.loadingComponent {
  height: 90vh;
  width: 100vw;
  margin: 0;
}

// OnBoardingPopupShimmerLoading
.loader {
  display: grid;
  gap: 32px;
}

// Shimmer
.base {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  animation-duration: 500ms;
}

.animateShimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
      to right, 
      var.$volcanoBlack3,
      var.$grey1,
      var.$volcanoBlack3
  ) 8% 18% 33%;
  background-size: 1000px 100%;
}

.noOpacity {
  opacity: 0;
}

@keyframes shimmer {
  0% {
      background-position: -1000px 0;
  }
  100% {
      background-position: 1000px 0;
  }
}
