@use '/src/style/variables.scss' as var;

.menu {
  background-color: var.$warmGray1;
  padding: 0 130px;
  top: 0;
  z-index: 1000;
}

.signUpTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerRoot {
  margin: 32px auto;
  max-width: var.$desktopContainerWidth;
  height: 174.6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoCntr {
  width: 40px;
  height: 40px;
}

@media (min-width: 960px) {
  .signUpTitle {
    position: relative;
    top: -17.5px;
  }
}

@media (max-width: 600px) {
  .signUpTitle {
    position: relative;
  }
  .headerRoot {
    width: 100%;
  }
  .menu {
    padding: 0 35px;
  }
}
