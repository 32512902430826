@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.leftCntr {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mandatoryField {
  color: var.$textBlack;
  margin-top: 50px;
}

.inputContainer {
  margin-top: 50px;
  width: 100%;
  max-width: 457px;
}

.inputField {
  border-radius: 8px;
}

.buttonContainer {
  max-width: 252px;
}

.buttonContainerRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

@media (min-width: 960px) {
  .root {
    position: relative;
    top: -50px;
    z-index: 1000;
    background-color: white;
    padding-top: 40px;
  }
}

@media (max-width: 960px) {
  .mainContainer {
    flex-direction: column;
  }
  .inputContainer {
    max-width: 100%;
  }
  .signup {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 25px;
  }
  .buttonContainer {
    max-width: 100%;
  }
  .rightbuttonContainer {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .root {
    padding-left: 16px;
    padding-right: 16px;
  }
  .descContainer {
    background-color: white;
    position: relative;
    top: -205px;
    padding: 16px;
    padding-top: 25px;
    margin-bottom: -250px;
    height: 150px;
  }
}
