@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.wrapper {
  overflow: hidden;
  background-color: var.$white;
  border-radius: 0.5rem;
  padding: 1.5rem 0;
  box-sizing: border-box;
  max-width: 971px;
}

.container {
  list-style: none;
  counter-reset: step;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.step {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  position: relative;
}

.step > span {
  align-self: center;
  top: 1px;
  left: calc(50% - 24px / 2 - 1px);
}

.step::after {
  height: 4px;
  width: calc(100% - 24px - 4px * 2);
  content: "";
  background: linear-gradient(to left, var.$warmGray3 50%, var.$snowGreen 50%);
  background-size: 200.5% 100%;
  background-position: right bottom;
  transition: .5s ease-out;
  position: absolute;
  top: calc((24px - 4px) / 2);
  left: calc(50% + 24px / 2 + 4px);
}

.step:last-child::after {
  content: none;
}

.label {
  margin-top: 4px;
  line-height: 16.8px;
  letter-spacing: 0.5px;
  color: var.$warmGray4;
  transition: font-size 0.1s;
}

.complete::after {
  background-position: left bottom!important;
}

.complete .label {
  color: var.$warmGray7;
}

.current::before {
  position: absolute;
  left: calc(50% - 24px / 2 - 1px);
  top: 1px;
  content: "";
  z-index: -1;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: var.$snowGreen;
  transform: scale(1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 1.7s;
}

.current .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: var.$warmGray7;
}

.preload {
  visibility: hidden;
}

@keyframes pulse {
  0% {
      transform: scale(1.05);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
      transform: scale(1.05);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.iconCheckmarkContainer {
  height: 24px;
  width: 24px;
  background-color: var.$snowGreen;
  border-radius: 50%;
}

.iconCheckmark {
  color: white;
  stroke: white;
  stroke-width: 3;
  transform: scale(0.56);
}

.iconPending {
  background-color: var.$warmGray3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.iconCurrent {
  background-color: var.$white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid var.$snowGreen;
}
