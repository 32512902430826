@use '/src/style/mixins/mediaQueries.scss' as mq;

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  border: none;
  padding: 0;
  margin: 0;
}

.imageContainer {
  width: calc(100vw - 7rem);
  height: 260px;
  overflow-x: auto;
  overflow-y: hidden;

  @include mq.tablet {
    width: 100%;
  }
}

.image {
  min-width: 700px;
  height: 260px;
}

.title {
  padding: 0;
}

.infoBox {
  width: 100%;
  box-sizing: border-box;
}