@use '/src/style/variables.scss' as var;

.link {
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: var.$clearBlue1;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: var.$clearBlue2;
}