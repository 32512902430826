@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

//Learn
.wrapperCoverWithOverlay {
  padding-bottom: 24px;
  background-color: var.$warmGray1;
}

.learn {

  & > div:nth-child(odd) {
    background-color: var.$warmGray1;
  }
  & > div:nth-child(even) {
    background-color: var.$white;
  }
  & > div:first-child {
    @media (max-width: var.$breakpoint-mobile) {
      top: 0;
      padding-top: 20px;
    }
  }
    
  .loader {
    height: 70vh;
    width: 100vw;
    margin: 0;
  }
  
  .anchorSection {
    position: relative;
  }
  
  .destinationCardContainer {
    max-width: var.$desktopContainerWidth;
    margin: auto;
    display: flex;
    gap: 16px;
    padding-inline: 16px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 16px;
    background-color: var.$white;
    z-index: 2;
    width: 100%;
    flex-wrap: wrap;
  
    @include mq.mobile {
      flex-wrap: nowrap;
    }
  }
  .arrowCardContainer {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    row-gap: 16px;
    padding-inline: 16px;
    box-sizing: border-box;
    width: 100%;
  
    @include mq.desktop {
      width: unset;
      flex-wrap: wrap;
      margin-left: 0;
    }
  }
  
  .sectionContainer {
    max-width: var.$desktopContainerWidth;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
}

//DetailsGallery
.detailsGallery{
    position: relative;
    width: 100%;
    max-width: var.$desktopContainerWidth;
    margin: 4px 0 0 0;
    display: flex;
  
    @include mq.desktop {
      width: calc(100% - 32px);
      margin: 0 auto 64px auto;
    }
  
    @media (max-width: 600px) {
      flex-wrap: wrap;
      align-items: flex-start;
      padding-bottom: 64px;
      height: auto;
      text-align: center;
    }
  
  .textContainer {
    width: calc(50% - 112px);
    min-width: calc(50% - 112px);
    padding-right: 102px;
  
    @media (max-width: 600px) {
      width: calc(100% - 10px);
      min-width: calc(100% - 10px);
      max-width: calc(100% - 10px);
      padding: 0;
    }
  }
  
  .imageContainer {
    position: absolute;
    display: inline-box;
    margin-left: 50%;
    height: 450px;
  
    @media (max-width: 600px) {
      position: relative;
      margin: auto;
      width: 374px;
      height: auto;
      min-width: 100%;
      padding-top: 32px;
    }
  }
  
  .imageClass {
    @media (max-width: 600px) {
      width: 100%;
      height: auto;
    }
  }
  
  .descriptionContainer {
    color: var.$warmGray6;
    padding-bottom: 32px;
    @include mq.desktop {
      word-break: break-all;
    }
  }
  
  .title {
    margin: 0;
    padding-bottom: 16px;
  }
  
  .buttonContainer {
    width: 240px;
  
    @media (max-width: 600px) {
      width: 264px;
      margin: auto;
    }
  }
  
  .image {
    height: 450px;
  
    @media (max-width: 600px) {
      width: 100%;
      height: auto;
    }
  }
  
}

//Custom Link
  .link {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    padding-right: 34px;
    &:hover {
      .link-arrow {
        right: -5px;
      }
    }
  }
  
  .smallText {
    font-size: 12px;
    padding-right: 18px;
    justify-content: flex-start;
  }
  
  .arrow {
    transition: all 0.2s;
    position: absolute;
    right: 0;
  }
  
  .noPadding {
    position: unset;
    padding-left: 20px;
  }

//title and description

.root {
  text-align: left;
  margin: auto;
  margin-bottom: 32px;
  margin-top: 32px;
  max-width: var.$desktopContainerWidth;
}

.root.isArticleContainer {
  margin-top: 0;
}

.root:not(.isArticle) {
  margin-bottom: 32px;
}

.root:not(.isArticle):not(.isArticleContainer) {
  margin-top: 32px;
}

@media (max-width: var.$breakpoint-tablet) {
  .root {
    margin: 0 16px;
  }
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;
}

.descriptionAsNode {
  font-size: 1rem;
}

.descriptionAsNode > p {
  font-size: 16px;
}

// TitleAndDescription
.titleAndDescription {
  text-align: left;
  margin: 0 16px;
  max-width: var.$articleContainerSize;

  @include mq.tablet {
    margin: 0 auto 32px auto;
  }

  .title {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .title.isArticle {
    text-align: unset;
  }

  .descriptionAsNode.isArticle {
    p {
      font-size: 16px;
    }

  }
}

.titleAndDescription.isArticleContainer {
  @include mq.tablet {
    margin-top: 32px;
  }
}

.titleAndDescription.isArticle {
  @include mq.tablet {
    margin-bottom: 0;
    margin-top: 32px;
  }
}

.titleAndDescription.hasMargin {
  @include mq.tablet {
    margin-bottom: 64px;
  }
}