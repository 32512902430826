@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/typography.scss' as text;

.title {
  font-weight: 500!important;
  margin-bottom: 30px;
  font-size: 24px!important;

  @include mq.tablet {}
}

.mandatoryField {
  @include text.body-small;
  font-size: 12px!important;
  padding-top: 10px;
  color: var.$textBlack;
}

.forgotPassword {
  font-size: 14px;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;

  @include mq.tablet {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    top: -50px;
    z-index: 1000;
    background-color: var.$white;
    padding-top: 40px;
  }
}

.mainCntr {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 16px;
  @include mq.tablet {
    flex-direction: row;
    gap: 120px;
  }
}

.rightCntr {
  align-items: center;
  margin-top: 25px;
  border-top: 1px solid var.$warmGray2;
  margin-left: 0;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 25px;

  @include mq.tablet {
    margin-top: 0;
    border-top: none;
  }
}

.leftCntr {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;

  @include mq.mobile {
    padding: 0;
  }
}

.inputCntr {
  margin-bottom: 40px;
  width: 100%;
  max-width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  
  @include mq.tablet {
    max-width: 457px;
    margin-bottom: 32px;
  }
}

.buttonCntrRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 10px;
  width: 100%;
}

.forgotPasswordCntr {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 45px;
  outline: none;
  width: 150px;
}

.buttonCntr {
  max-width: 252px;
}
