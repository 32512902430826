@use '/src/style/variables.scss' as var;

.article {
  position: relative;
  height: 368px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.link {
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  border: 2px solid transparent;
  overflow: hidden;
}

.link:focus {
  border: 2px solid black;
}

.link:focus > img {
  transform: scale(1.2);
}

.image {
  width: 100%;
  height: 100%;
  transition: all 0.8s;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.7);
  box-sizing: border-box;
}

.textContainer {
  position: absolute;
  top: calc(50% - 44px/2 + 78px);
  left: 0;
  width: 100%;
  z-index: 1;
  color: var.$white;
}

.description {
  margin-top: 12px;
  font-size: 16px;
  display: block;
}
