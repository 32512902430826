@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.cardsContainer {
  width: 100%;
  margin-block: 0;
  padding-inline: 0;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include mq.mobile {
    flex-direction: row;
  }
}
