@use '/src/style/variables.scss' as var;

// TabTernary
.tabTernaryContainer {
  width: '100%'
}

// TabTernaryHeader
.tabTernaryHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 8px;

  .tab {
    color: var.$textBlack;
    background-color: transparent;
    font-size: 14px;
    cursor: pointer;
    border-style: none;
    padding: 0;
  }

  .tab span {
    font-weight: 400;
  }

  .tab:hover {
    color: var.$warmGray5;
  }

  .active {
    text-decoration: underline;
  }

  .active span {
    font-weight: 500;
  }
}
