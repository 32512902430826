@use '/src/style/variables.scss' as var;

.btnRow {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.modalTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var.$black;
  margin-bottom: 16px;
}

.scrollableArea {
  max-height: 656px;
  overflow-y: auto;
  display: block;
  font-family: 'Atlas Grotesk Regular', sans-serif;
  font-size: 14px;
  color: var.$warmGray6;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 600px) {
  .modalCntr {
      padding-bottom: 10px;
  }
  .scrollableArea {
      max-height: none;
  }
}
