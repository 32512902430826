@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.rightButtonCntr {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 30px;
  height: 44px;
}

.rightTitle {
  color: var.$white;
  margin-left: 16px;
  margin-top: 0;
  font-size: 17px!important;
}

.rightDesc {
  color: var.$white;
  margin: 0 16px;
  font-size: 14px;
}

.signup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 35px;
  background-color: var.$textBlack;
  border-radius: 8px;
  gap: 16px;
  width: 100%;
  padding: 25px 0;

  @include mq.tablet {
    padding-top: 0;
  }
}

.buttonWhite {
  width: 120px;
  border: none!important;
  color: black!important;
  line-height: 1!important;
  margin: 0 16px;

  &:hover {
    background-color: var.$warmGray3!important;
    border: none;
  }
}

@include mq.tablet {
  .rightTitle {
    margin-top: 20px;
    margin-left: 30px;
  }

  .rightDesc {
    font-size: 16px;
    margin-left: 30px;
  }

  .buttonWhite {
    margin: 0;
  }

  .rightButtonCntr {
    max-width: unset;
    width: unset;
    margin-left: auto;
    margin-top: 20px;
  }
}
