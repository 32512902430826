@use '/src/style/variables.scss' as var;
@use '/src/style/mixins/mediaQueries.scss' as mq;

.focalPointAssetContainerHidden {
  opacity: 0;
  transition: all 1s ease-out;
}

.focalPointAssetContainerAppear {
  opacity: 1;
}

.title {
  margin-top: 20px;
  margin-bottom: 24px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.textContainer {
  flex: 1;
  width: 100%
}

.focalPointAssetContainer {
  max-width: 100%;
  max-height: 468px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.videoContainer {
  position: relative;
  height: 193px;
  background-size: cover;
  background-position: center;
}

@include mq.tablet {
  .headerContainer {
    flex-direction: row;
    gap: 32px;
  } 

  .focalPointAssetContainer {
    max-width: 832px;
  }

  .videoContainer {
    height: 468px;
  }
}

@include mq.laptop {
  .textContainer {
    width: unset;
  }
}