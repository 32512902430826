@use '/src/style/variables.scss' as var;

.status {
  border-radius: 12px;
  height: 12px;
  padding: 6px 14px;
  font-family: 'Atlas Grotesk Medium', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: var.$white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 10px;
}

.statusText {
  font-family: 'Atlas Grotesk Medium', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin-top: 8px;
}

.root {
  width: 272px;
  min-width: 272px;
  border: solid 1px var.$warmGray2;
  background-color: var.$white;
  padding: 15px;
  box-sizing: border-box;
  cursor: default;
  margin-right: 16px;
}

.clickableRoot {
  width: 272px;
  min-width: 272px;
  border: solid 1px var.$warmGray2;
  background-color: var.$white;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 16px;
  margin-right: 16px;
}

.clickableRoot:last-child {
  margin-Right: 0
}

.clickableRoot:hover {
  border-color: var.$red;

  &.hx {
    border-color: var.$hxBlue1;
  }
}

.contentRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text {
  font-family: 'Atlas Grotesk Regular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var.$textBlack;
}
