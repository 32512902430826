@use '/src/style/variables.scss' as var;

// CabinDiscountOverlay
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var.$warmGray2;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.overlayForm {
  margin: 100px 100px 200px 50px;
}

.overlayHeaderText {
  font-size: 14px!important;
}

.discountItems {
  display: flex;
  justify-content: space-between;
}

.discountLink {
  color: var.$warmGray6;
  font-size: 14px!important;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 32px;
  border: none;
  background: none;
}

// CabinsSelector
.cabinItem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 3;
}

.cabinItem:not(:last-child) {
  border-bottom: 1px solid var.$warmGray1;
  padding-bottom: 16px;
}

.cabinItem:not(:first-child) {
  padding-top: 16px;
}

.addCabinButton {
  margin-top: 20px;
}

.inputsGroup {
  border: 1px solid var.$volcanoBlack4;
  border-radius: 10px;
  padding: 4px 0 4px 12px;
}

.headerLine {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

// DiscountVoyageList
.discountList {
  list-style-type: none;
  padding: 0;
}

.category,
.value {
  margin: 0;
  font-size: 14px;
}

.cabinTitle {
  padding-bottom: 16px;
}