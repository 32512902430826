@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.imageViewContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: .2s ease-in-out; 
  cursor: pointer;
  
  &:hover {
    transform: scale(1.01); 
    .imageViewOverlay {
      opacity: 0.2;
    }
  }
}

.imageViewImageMin {
  width: unset;
  object-fit: cover;
  object-position: center;
  transition: .2s ease;
}

.imageViewImage {
  background: var.$white;
  max-width: calc(100vw - 8rem);
  max-height: calc(100vh - 4rem);
}

.imageViewOverlay {
  transition: .2s ease;
  opacity: 0;
  position: absolute;
  inset: 0;
  background-color: var.$black;
}
