@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/mixins/truncateLines.scss' as truncateLines;
@use '/src/style/variables.scss' as var;

.container {
  max-width: var.$desktopContainerWidth;
  margin: 0 auto;
  padding-bottom: var.$contentPaddingBottom;
}

// LoginWaitEmailVerification
.root {
  margin: 0 auto;
  padding-bottom: var.$contentPaddingBottom;
  max-width: var.$desktopContainerWidth;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding-left: 16px;
  padding-right: 16px;

  @include mq.mobile {
    position: relative;
    top: -50px;
    z-index: 1000;
    background-color: var.$white;
    padding-top: 40px;
  }

  @include mq.tablet {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.bold {
  font-weight: bold;
}

.desc {
  color: var.$warmGray6;
  margin-bottom: 32px;
  max-width: 720px;
}

.label {
  font-weight: 500!important;
}

.reSendEmailButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}