@use '/src/style/variables.scss' as var;


.loader {
  height: 90vh;
  width: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 960px) {
    align-items: center;
  }
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    .checkBoxContainer {
      width: 100%;
    }
  }
}

.buttonGridItem {
  padding-top: 32px;
  width: 262px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 25px 0!important;
  }
}

.inputsContainer {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(457px, 1fr));
  margin-top: 45px;
  width: 100%;
}

@media (max-width: 600px) {
  .inputsContainer {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .inputsContainer {
    justify-content: center;
    align-items: center;
    grid-template-columns: unset;
      margin-top: 85px;
  }
}

.mandatoryField {
  margin-top: 10px;
}

.termsButton {
  background: none;
  color: var.$clearBlue1;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: column;
  height: 1.5rem;
}

// Registration
.registrationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-bottom: var.$contentPaddingBottom;
  max-width: 1168px;
  padding-left: 50px;
}

.descContainer {
  width: 100%;
}

@media (max-width: 600px) {
  .descContainer {
    background-color: var.$white;
    position: relative;
    top: -220px;
    padding: 16px;
    padding-top: 25px;
    margin-bottom: -285px;
    height: 180px;
    width: 100%;
    box-sizing: border-box;
  }

  .registrationContainer {
    padding-top: 30px;
    padding-left: 0;
  }
}

@media (max-width: 960px) {
  .registrationContainer {
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 960px) {
  .registrationContainer {
    position: relative;
    top: -50px;
    z-index: 1000;
    background-color: white;
    padding-top: 32px;
  }
}