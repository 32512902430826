.container {
  display: grid;
  gap: 2rem;
  max-width: 600px;
}

.form {
  max-width: 600;
  width: 100%;
  display: grid;
  gap: 2rem;
}