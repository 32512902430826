@import '/src/style/variables.scss';

@mixin mobile {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin max-mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin max-tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin max-laptop {
  @media (max-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin max-desktop {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}
