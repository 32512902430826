@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 4rem;
}

.backdropCloseButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.backdropContent {
  display: inline-block;
}
