@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

// TextInput
.root {
  width: 100%;
}

.passwordIcon {
  padding-right: 20px;
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.passwordInput {
  height: 58px;
  width: 100%;
}

.rightIcon {
  padding-right: 20px;
  position: absolute;
}

.leftIcon {
  padding-left: 20px;
  position: absolute;
  align-self: flex-start;
  z-index: 99;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
