@use '/src/style/mixins/mediaQueries.scss' as mq;
@use '/src/style/variables.scss' as var;

// BackToSearchErrorMessage
.error {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

// MoreInformationBar
.moreInformationBar {
  margin: 0;
  padding: 0;
  background-color: var.$volcanoBlack9;

  .buttonContainer {
    background-color: var.$volcanoBlack1;
    height: auto;

    &:hover {
      background-color: var.$grey4;
    }
  }

  .isCoastalButtonContainer {
    background-color: var.$navyBlue3;

    &:hover {
      background-color: var.$navyBlue4;
    }
  }

  .backToTopButton {
    background-color: transparent;
    border: none;
    color: var.$volcanoBlack2;
  
    &:hover {
      color: var.$white;
      cursor: pointer;
    }
  }

  .isCoastalBackToTopButton {
    color: var.$volcanoBlack3;
  }

  .listItem {
    color: var.$white;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid var.$white;
    }
  }

  .isBookingFunnelListItem {
    &:hover {
      border-bottom: 2px solid transparent;
    }
  }

  .isStickyListItem {
    color: var.$volcanoBlack2;
  }

  .isStickyCoastalListItem {
    color: var.$volcanoBlack3;
  }

  .activeListItem {
    color: var.$white;
    &:hover {
      border-bottom: 2px solid var.$white;
    }

    @include mq.laptop {
      border-bottom: 2px solid var.$white;
    }
  }

  .isBookingFunnelActiveListItem {
    &:hover {
      border-bottom: 2px solid transparent;
    }

    @include mq.laptop {
      border-bottom: 2px solid transparent;
    }
  }

  .container {
    width: 100%;
    max-width: var.$wideDesktopWidth;
    margin: auto;
    display: inline;
  
    @include mq.laptop {
      display: flex;
    }
  }

  .isStickyContainer {
    max-width: var.$desktopContainerWidth
  }

  .invertedBlackButton {
    background-color: var.$white !important;
    color: var.$black !important;
  
    &:hover {
      background-color: var.$grey1 !important;
      color: var.$black !important;
    }
  }

  .listContainer {
    list-style: none;
    padding: 0 0 20px 24px;
    margin: 0;
    display: grid;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    gap: 5px;
  
    @include mq.laptop {
      padding: 0;
      display: inline-flex;
      gap: 40px;
    }
  }
}

.isCoastal {
  background-color: var.$navyBlue2;
}

.sticky {
  position: sticky;
  top: 70px;
  z-index: 10;
}

.accordionButton {
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1rem 0;
  color: var.$white;
  font-size: 14px !important;
  cursor: pointer;

  svg {
    transform: rotate(0deg);
    transition: transform 300ms;
  }

  [aria-expanded="true"] svg {
    transform: rotate(180deg);
    transition: transform 300ms;
  }
}

.searchLink {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 299px;
  margin: 0;

  @include mq.laptop {
    margin: 6px 0;
  }
}

a.linkText {
  color: inherit;
  text-decoration: none;
  display: flex;
  padding: 0.5rem 1rem;

  &:hover {
    text-decoration: underline;
    color: var.$white;
  }
}

a.isLinkTextSticky {
  padding: .5rem 0;

  &:hover {
    text-decoration: none
  }
}