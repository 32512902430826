@use '/src/style/mixins/mediaQueries.scss' as mq;

.shortVoyageFields {
  margin-top: 24px;
}

.iconButton {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

.iconArrowUpDown {
  transform: rotate(90deg) scaleX(-1);
  box-sizing: content-box;
  stroke-width: 0.25;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  
  @include mq.tablet {
    flex-direction: row;
  }
}

.radioButtons {
  margin-top: 16px;

  div {
    flex-direction: column;
    align-items: start;
    gap: 16px;
    height: unset;
    margin-top: 0;
  }

  @include mq.tablet {
    margin-top: 24px;

    div {
      flex-direction: row;
    }
  } 
}