@use 'variables.scss' as var;
@use 'mixins/typography.scss' as text;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: unset;
}

*:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

*:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}

body {
  @include text.default;
  color: var.$textBlack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

h1 {
  @include text.headline-1;
}

h2 {
  @include text.headline-2;
}

h3 {
  @include text.headline-3;
}

h4 {
  @include text.headline-4;
}

h5 {
  @include text.headline-5;
}

a {
  color: var.$clearBlue1;
  text-decoration: none;
}

p {
  @include text.body;
}

button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[disabled] {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}

label {
  font-weight: 400;
}

img {
  max-width: 100%;
  vertical-align: center;
  border: 0;
}
img:not([src]) {
  opacity: 0;
}

ul {
  padding-inline-start: 40px;
}

ol {
  padding-inline-start: 20px;
}

hr {
  border-top: 1px solid color-grey('border');
}

// strange classes
.notif-overlay {
  margin: 16px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.no-transition {
  transition-duration: 0s !important;
}

.ajax-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 1040;
  background: radial-gradient(#fff, rgba(#fff, 0.9) 60%);
  text-align: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 999;
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-name,
.react-datepicker__day {
  padding: 4px;
}